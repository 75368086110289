<template>
<div class="page">
  <RecoveryVolume></RecoveryVolume>
</div>
</template>

<script>
import RecoveryVolume from '@/components/RecoveryVolume'
export default {
  components: {
    RecoveryVolume
  }
}
</script>

<style scoped>
.page{
  width: 956px;
  height: 438.6px;
  margin: 0 auto;
  /*width: 100%;*/
  /*height: 100%;*/
  overflow: hidden;
  background: #00000073;
}

</style>
