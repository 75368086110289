<template>
  <div class="areaFather" v-show="disabled">
    <div class="dataBox">
      <div class="title" v-if="isForS == true">
        <div class="left">智能厢房回收统计</div>
        <div class="right">
          <div>
            已安装小区<span
              >{{ finishArea || zero }}/{{ areaNum || zero }}</span
            >
          </div>
          <div @click="getFList">
            <i class="el-icon-refresh"></i>
          </div>
        </div>
      </div>
      <div
        class="title"
        style="cursor: pointer"
        @click="backList"
        v-if="isTows == true"
      >
        <div class="left">
          <img src="../assets/img/向左箭头.png" alt="" /> {{ itemName }}
        </div>
      </div>

      <div
        class="title"
        style="cursor: pointer"
        @click="backListtwo"
        v-if="isThrees == true"
      >
        <div class="left">
          <img src="../assets/img/向左箭头.png" alt="" /> {{ itemName }}
          <span>--{{ threename }}</span>
        </div>
      </div>

      <div class="itemBox" id="fBox" v-if="isForS == true">
        <dv-loading v-if="loading"></dv-loading>
        <div
          :id="'fItem' + (index + 1)"
          class="items"
          v-for="(item, index) in fList"
          :key="index"
          @click="goSecond(item, index + 1)"
        >
          <div class="top3" v-if="index < 3">
            <img src="../assets/img/top.png" alt="" />
          </div>
          <div class="index">{{ index + 1 }}</div>
          <div class="name">{{ item.name }}</div>
          <div class="today">
            今日回收<span>{{ item.today }}kg</span>
          </div>
          <div class="num">
            厢房数量<span>{{ item.area_num }}</span>
          </div>
          <div class="arr"><img src="../assets/img/蓝箭头.png" alt="" /></div>
        </div>
      </div>
      <div class="itemBox" v-if="isTows == true">
        <div
          class="items"
          v-for="(item, index) in sList"
          :key="index"
          @click="goThree(item, index + 1)"
        >
          <div class="index2">{{ index + 1 }}</div>
          <div class="name2">{{ index + 1 }}号厢房</div>
          <div class="today2">
            今日回收<span>{{ item.today.sum }}kg</span>
          </div>
          <div class="num2">
            近30天<span>{{ item["30_days"].sum }}kg</span>
          </div>
          <!-- <div class="arr"><img src="../assets/img/蓝箭头.png" alt="" /></div> -->
          <!-- <div
            class="type"
            style="color: #90FFFF;"
            v-if="item.health.status == 1"
          >
            已开启
          </div>
          <div class="type" style="color: #DC7B22;" v-else>未开启</div> -->
          <div class="arr" v-if="item.health.status == 1">
            <img src="../assets/img/open.png" alt="" />
          </div>
          <div class="arr" v-else>
            <img src="../assets/img/close.png" alt="" />
          </div>
        </div>
      </div>

      <div class="itemBox" v-if="isThrees == true">
        <div class="items" v-for="(item, index) in ThreeList" :key="index">
          <div class="index2">{{ index + 1 }}</div>
          <div class="name2">{{ item.name }}</div>
          <div class="today2">
            今日回收<span>{{ item.today.sum }}kg</span>
          </div>
          <div class="num2">
            近30天<span>{{ item["30_days"].sum }}kg</span>
          </div>
          <!-- <div
            class="type"
            style="color: #90FFFF;"
            v-if="item.health.status == 1"
          >
            已开启
          </div>
          <div class="type" style="color: #DC7B22;" v-else>未开启</div> -->
          <!-- <div class="arr" v-if="item.health.status == 1">
            <img src="../assets/img/open.png" alt="" />
          </div>
          <div class="arr" v-else>
            <img src="../assets/img/close.png" alt="" />
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// 引入各个接口
import { GetArea } from "@/api/rubbish";
export default {
  props: ["disabled", "areaNum"],
  data() {
    return {
      realTop: "",
      loading: false,
      zero: 0,
      finishArea: "",
      isForS: true,
      isTows: false,
      isThrees: false,
      itemName: "",
      fList: [],
      threename: "",
      ThreeList: [],
      sList: [
        {
          id: 1,
          name: "1号厢房",
          today: "160kg",
          month: "160kg",
          type: 0,
        },
        {
          id: 1,
          name: "1号厢房",
          today: "160kg",
          month: "160kg",
          type: 1,
        },
      ],
    };
  },
  created() {
    this.getFList();
  },
  mounted() {},
  methods: {
    // 清除老数据
    clean() {
      this.fList = [];
      this.isForS = true;
      this.ThreeList = [];
      this.sList = [];
      this.isTows = false
      this.isThrees  = false
    },
    // 请求第一层列表
    async getFList() {
      this.fList = [];
      this.loading = true;
      const { data } = await GetArea();
      console.log(data, "areararararara");
      if (data.code == 200) {
        this.loading = false;
        this.fList = data.data;
        this.finishArea = data.data.length;
        // this.fList[0].chambers[0]["today"].count=143
        // this.fList[0].chambers[1]["today"].count=100
        this.fList.map((item, index) => {
          item.area_num = item.chambers.length;
          item.today = 0;
          item.chambers.map((item1, index1) => {
            item.today += Number(item1["today"].count);
          });
        });
      }
    },
    // 返回第一层列表
    backList() {
      this.isForS = true;
      this.isTows = false;
      this.isThrees = false;
      console.log(this.realTop, "realTop");
      // setTimeout(() => {
      //   let doms = document.getElementById("fBox");
      //   console.log(doms, "doms");
      //   doms.scrollTop = this.realTop;
      // }, 500);
      this.$nextTick(() => {
        let doms = document.getElementById("fBox");
        console.log(doms, "doms");
        doms.scrollTop = this.realTop - 55;
      });
    },
    backListtwo() {
      this.isForS = false;
      this.isTows = true;
      this.isThrees = false;
    },
    // 去第二层列表
    goSecond(item, index) {
      this.itemName = item.name;
      this.sList = item.chambers;
      console.log(item, "item");
      this.isForS = false;
      this.isTows = true;
      console.log(index, "index");
      let dom = document.getElementById(`fItem${index}`, "6666");
      console.log(dom, "dommmm");
      this.realTop = dom.offsetTop;
    },

    goThree(item, index) {
      this.threename = index + "号厢房";
      console.log(item, index);
      this.ThreeList = item.bins;
      console.log(item, "binsbinsbinsbins");
      this.isForS = false;
      this.isTows = false;
      this.isThrees = true;
    },
  },
  watch: {
    disabled: {
      handler: function (newV, oldV) {
        console.log(newV, "newV");
        if (newV == true) {
          // this.getFList();
        } else {
          // this.clean();
        }
      },
      deep: true,
    },
    areaNum: {
      handler: function (newV, oldV) {
        console.log(newV, "newV");
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/index.less";

/* 滚动条 */
body *::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

body *::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 2px;
}

body *::-webkit-scrollbar-thumb {
  background: #c1c1c1;

  border-radius: 10px;
}

body *::-webkit-scrollbar-thumb:hover {
  background: #333;
}

body *::-webkit-scrollbar-corner {
  background: #fff;
}

.areaFather {
  position: absolute;
  left: 21.5%;
  bottom: 2.6%;
  z-index: 99;
  // background-color: rgba(0, 0, 0, 0.45);
  // padding: 20px 15px;
}

.dataBox {
  // width: 530px;
  // height: 300px;
  width: 600px;
  height: 340px;
  background: url("../assets/img/外框.svg") no-repeat;
  // background-color: aqua;
  // mar;
  background-size: 100% 100%;
  box-sizing: border-box;
  padding: 0 20px;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 15px 0 10px 0;

    .left {
      color: #01d3dc;
      font-size: 23px;
      font-weight: bold;
      display: flex;
      align-items: center;

      img {
        margin-right: 7px;
        height: 22px;
      }
    }

    .right {
      color: #fff;
      font-weight: bold;
      font-size: 20px;
      display: flex;
      align-items: center;

      span {
        color: #01d3dc;
      }

      // &:last-of-type{
      //   margin-left: 20px;
      // }
      div:last-of-type {
        cursor: pointer;
        color: #01d3dc;
        margin-left: 10px;
      }
    }
  }

  .itemBox {
    height: 72%;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 5px 0 0;

    .items {
      cursor: pointer;
      height: 17.1%;
      display: flex;
      // justify-content: space-between;
      align-items: center;
      color: #fff;
      border-radius: 4px;
      border: 1px solid #5b93ae;
      background-color: rgba(59, 74, 79, 0.3);
      margin-bottom: 5px;
      position: relative;

      // text-align: center;
      .arr {
        margin-left: 5px;
        width: 20px;
        height: 20px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .index {
        text-align: center;
        width: 9%;
      }

      .name {
        text-align: left;
        width: 25%;
      }

      .today,
      .num {
        font-size: 14px;
        // padding: 3px 0;
        padding: 3px 12px;
        background-color: rgba(67, 92, 104, 0.7);
        margin-right: 8px;
        border-radius: 4px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;

        // text-align: center;
        span {
          margin-left: 12px;
          color: #8af6a3;
        }
      }

      .today {
        width: 35%;
      }

      .num {
        width: 21.8%;
      }

      .top3 {
        height: 10px;
        width: 10px;
        position: absolute;
        top: -11%;
        left: 0.5%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .index2 {
        text-align: center;
        width: 7%;
      }

      .name2 {
        text-align: left;
        width: 20%;
      }

      .today2,
      .num2 {
        font-size: 14px;
        // padding: 3px 0px;
        padding: 3px 10px;
        background-color: rgba(67, 92, 104, 0.7);
        margin-right: 8px;
        border-radius: 4px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;

        // text-align: center;
        span {
          margin-left: 12px;
          color: #8af6a3;
        }
      }

      .type {
        font-size: 14px;
        padding: 3px 0px;
        // padding: 3px 10px;
        background-color: rgba(67, 92, 104, 0.7);
        margin-right: 8px;
        border-radius: 4px;
        text-align: center;
      }

      .today2 {
        width: 31.8%;
      }

      .num2 {
        width: 31.8%;
      }

      .type {
        width: 14%;
      }
    }
  }
}
</style>
