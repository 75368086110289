import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '@/router/routes'
import { getLocal } from '@/utils/local'

Vue.use(VueRouter)

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.title){
    document.title = to.meta.title
  }
  var token = getLocal('large_token')
  if (token) {
    if (to.path === '/login') return next('/')
    next()
  } else {
    // console.log(to)
    if (to.path !== '/login') return next('/login')
    next()
    // next('/login')
  }
})

export default router
