import axios from 'axios'
import { baseURL } from '@/config'
import qs from 'qs'
import { getLocal } from './local'

class HttpRequest {
  constructor (baseUrl = baseURL) {
    this.baseUrl = baseUrl
    this.queue = {}
  }

  getInsideConfig () {
    const config = {
      baseURL: this.baseUrl,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
    return config
  }

  // 定义heasers
  // 拦截器
  // 请求拦截器
  interceptors (instance) {
    instance.interceptors.request.use(

      config => {
        const token = getLocal('large_token')

        // 添加全局loading
        // 判断token是否过期结合user.js方法authorization
        // config.headers['Authorization']= getToken()

        config.data = qs.stringify(config.data) // 转为formdata数据格式

        if (token) {
          // 判读token是否存在
          config.headers.token = token
        }
        if (config.url == '/recycling_weight_top') {
          // config.baseURL = 'http://www.haiwojiamei.com/admin/bi'
        }

        return config
      },
      error => {
        // 错误
        return Promise.reject(error)
      }
    )
    // 响应拦截器
    instance.interceptors.response.use(
      res => {
        // console.log(res)

        return res
      },
      error => {
        // 错误

        return Promise.reject(error)
      }
    )
  }

  request (options) {
    const instance = axios.create()

    options = Object.assign(this.getInsideConfig(), options)

    this.interceptors(instance, options.url)
    return instance(options)
  }
}

export default HttpRequest
