<template>
  <div class="com-father">
    <div ref="classification_ref" class="com-classification">
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { GetRecycleClass } from '@/api/home'
import { steLoading } from '@/utils/loading'
export default {
  data () {
    return {
      chartInstance: null, // 初始化的对象
      allData: null, // 获取的数据
      standardsize: 1 // 全局标准值
    }
  },
  mounted () {
    this.initChart() // 调用初始化
    this.getData() // 获取服务器数据
    window.addEventListener('resize', this.screenAdapter)
    // 屏幕适配
    this.screenAdapter()
  },
  destroyed () {
    // 移除监听
    window.removeEventListener('resize', this.screenAdapter)
  },
  methods: {
    // 初始化
    initChart () {
      this.chartInstance = echarts.init(this.$refs.classification_ref, 'walden')

      // 对图表初始化配置控制
      const initOption = {
        title: { // 标题设置
          text: '分类类别',
          textStyle: {
            fontWeight: 'bold'
          },
          left: '3%',
          top: '3%'
        },

        legend: {
          // orient: 'horizontal',


        },
        color: ['#0082dc','#fddd9b','#00bb37','#e80c0c', '#8d97a5'],
        // '#e80c0c' ,'#fddd9b', '#00bb37'
        series: [
          {
            name: '分类类别',
            type: 'pie',
            radius: ['37%', '61%'], // 大小
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontWeight: 'bold',
                color: '#fff',
                borderWidth: 0,
                formatter: (err) => {
                  const ss = "{b|" + err.name + "}" + '\n' + '\n' + "{c|" + err.value + '%' +"}"
                  // '{b|'+err.name'}'+'}'
                  // '{c|'+err.name'}'+'}'
                  return ss
                }
              }
            },
            labelLine: {
              show: false
            }

          }
        ]
      }

      this.chartInstance.setOption(initOption)
    },
    // 获取服务器数据
    async getData (parameter) {
      // 获取分类数据
      steLoading(this.chartInstance)
      const { data } = await GetRecycleClass(parameter)
      // console.log(data)
      this.allData = data.data.recycle_class_rate
      this.allData[0].name = '可回收物'
      this.chartInstance.hideLoading()
      this.updataChart()
    },
    // 更新图表
    updataChart () {
      const DataOption = {
        series: [
          { data: this.allData }

        ]
      }
      this.chartInstance.setOption(DataOption)
      // 一下是设置默认是状态
      let index = 0 // 高亮索引
      this.chartInstance.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: index

      })

      this.chartInstance.on('mouseout', (e) => {
        index = e.dataIndex
        this.chartInstance.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: e.dataIndex

        })
      })
      this.chartInstance.on('mouseover', (e) => {
        if (e.dataIndex != index) {
          this.chartInstance.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: index

          })
        }
      })
    },
    // 屏幕适配
    screenAdapter () {
      this.standardsize = this.$refs.classification_ref.offsetWidth / 335
      const adapterOption = {
        title: { // 标题设置
          textStyle: {
            fontSize: 16 * this.standardsize
          }
        },
        legend: {
          width:180 * this.standardsize,
          right: 20 * this.standardsize,
          top: 50 * this.standardsize,
          itemWidth: 11 * this.standardsize,
          itemHeight: 11 * this.standardsize,
          itemGap: 8 * this.standardsize,
          textStyle: {
            fontSize: 12 * this.standardsize,
            backgroundColor:'#707070',
            padding: [6, 6],
            borderRadius:4
          }

        },
        series: [
          {
            center: [80 * this.standardsize, 95 * this.standardsize], // 位置
            emphasis: {
              label: {
                rich: {
                  b: {
                    fontSize: 11 * this.standardsize
                  },
                  c: {
                    fontSize: 18 * this.standardsize,
                    fontWeight: 'bold'

                  }
                }
              }
            },
            labelLine: {
              show: false
            }

          },
          {
            name: '外边框',
            type: 'pie',
            clockWise: false,
            radius: ['70%', '70%'], // 边框大小
            center: [80 * this.standardsize, 95 * this.standardsize], // 位置
            data: [{
              value: 10,
              itemStyle: {
                normal: {
                  borderWidth: 3, // 设置边框粗细
                  borderColor: '#485A72'// 边框颜色
                }
              },
              label: {
                show: false
              }
            }]
          }
        ]
      }

      this.chartInstance.setOption(adapterOption)
      // 手动调用图表对象resize
      this.chartInstance.resize()
    }
  }
}
</script>

<style scoped>

</style>
