import axios from './index'
// 获取注册排名
export const Getraknlist = params => {
  return axios.request({
    url: '/community_know_rate_rank',
    method: 'get',
    params
  })
}
// 获取参与排名
export const Getparticipate = params => {
  return axios.request({
    url: '/community_part_rate_rank',
    method: 'get',
    params
  })
}
// 获取减量排名
export const Getdecrement = params => {
  return axios.request({
    url: '/recycling_weight_rank',
    method: 'get',
    params
  })
}
// 获取增长排名
export const Getusergrowth = params => {
  return axios.request({
    url: '/user_growth_rank',
    method: 'get',
    params
  })
}
// 获取小区列表
export const Getcommunity = params => {
  return axios.request({
    url: '/community_list',
    method: 'get',
    params
  })
}
// 减量排名
export const Getweightrank = params => {
  return axios.request({
    url: '/recycling_weight_rank',
    method: 'get',
    params
  })
}
