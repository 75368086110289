import Vue from 'vue'
// 引入全局样式文件
import './assets/css/global.css'

import './assets/css/suspensing.css'

// 引入dava组件
import './plugins/datav'

import './plugins/element.js'

import App from './App.vue'
import router from './router'
// px2rem 自适应
// import '@/utils/flexble'
import {echartsSize} from '@/utils/foisfix'
// 引入主题
import './assets/js/walden'
Vue.config.productionTip = false

Vue.prototype.echartsSize = echartsSize
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
