<template>
  <div class="com-father">
    <div ref="tan_ref" class="com-tan4">
<div class="an">
  <div v-for="item in andata" :key="item.id" @click="anbut(item.id)" :class="[{'an-Select':item.type},'an-item']">
    {{item.name}}
  </div>

</div>
      <el-table
        ref="table"
        :data="allData"
        stripe
        border
        :cell-style="{'text-align':'center'}"
        :header-cell-style="{'text-align':'center'}"
        style="width: 100%"
        height="300"
        >
        <el-table-column
          prop="community_name"
          width="150"
          label="所属社区">
        </el-table-column>
        <el-table-column
          prop="yuyueshijian"
          width="240"
          label="下单时间">
        </el-table-column>
        <el-table-column
          prop="create_time"

          label="预约时间">
        </el-table-column>
        <el-table-column
          prop="state"
          label="订单状态">
        </el-table-column>

        <el-table-column
          prop="recycle_total_num"
          label="回收量(Kg)">
        </el-table-column>
        <el-table-column
          label="负责人">
          <template  slot-scope="scope">
            {{scope.row.su_name?scope.row.su_name:'暂无负责人' }}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { GetRecyclin } from '@/api/home'
import { GetOncall } from '@/api/tan'
export default {
  data () {
    return {
      getdataporm: {
        genra: 9,
        simp: 0,
        community_id: null
      },
      shide: {
        appointment_end_time: '2021-06-30 02:00:00',
        appointment_start_time: '2021-06-30 01:00:00'
      },
      andata: [
        // 按钮数据
        {
          id: 9,
          name: '全部',
          type: true
        },
        {
          id: 0,
          name: '待接单',
          type: false
        },
        {
          id: 1,
          name: '待回收',
          type: false
        },
        {
          id: 2,
          name: '已回收',
          type: false
        },
        {
          id: 3,
          name: '已完成',
          type: false
        },

      ],
      chartInstance: null, // 初始化的对象
      allData: [], // 获取的数据
      standardsize: 1 // 全局标准值

    }
  },
  mounted () {
    // this.getData() // 获取服务器数据
  },

  methods: {
    // 获取服务器数据
    async getData (tyep, parameter) {
      // 获取排名数据
      if (parameter !== undefined) {
        this.getdataporm.community_id = parameter.community_id
        this.getdataporm.time_start = parameter.time_start
        this.getdataporm.time_end = parameter.time_end
      }

      const { data: { data: res } } = await GetOncall(this.getdataporm)
      this.allData = res.info

      // 转换所需格式
      this.allData.forEach(item => {
        item.yuyueshijian = this.shizhuan(item.appointment_start_time, item.appointment_end_time)
      })
      console.log(this.allData)
    /*  this.tableScroll() */
    },
    shizhuan (e, e1) {
      /* appointment_end_time: "2021-06-30 02:00:00",
        appointment_start_time: "2021-06-30 01:00:00" */
      /* console.log(e.slice(0, 4))
      console.log(e.slice(5, 7))
      console.log(e.slice(8, 11))
      console.log(e.slice(11, 16)) */
      return `${e.slice(0, 4)}年${e.slice(5, 7)}月${e.slice(8, 10)}日 ${e.slice(11, 16)}-${e1.slice(11, 16)}`
    },

    tableScroll () { // 自动滚动放弃
      // 拿到表格挂载后的真实DOM
      const table = this.$refs.table
      // 拿到表格中承载数据的div元素
      const divData = table.bodyWrapper
      // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果(此配置为每100毫秒移动1像素)
      setInterval(() => {
        // 元素自增距离顶部1像素
        divData.scrollTop += 1
        // 判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
        if (divData.clientHeight + divData.scrollTop + 1 > divData.scrollHeight) {
          // 重置table距离顶部距离
          divData.scrollTop = 0
        }
      }, 50)
      const that = this
      window.onresize = () => {
        return (() => {
          window.screenHeight = document.body.clientHeight
          that.clientHeight = window.screenHeight
        })()
      }
    },
    // 点击按钮
    anbut (ed) {
      this.andata.forEach(item => {
        item.id === ed ? item.type = true : item.type = false
      })
      this.getdataporm.genra = ed
      this.getData()
    }
  }
}
</script>

<style lang="less"  scoped>
@import '~@/assets/css/index.less';
.com-tan4{
  height: 300px;
  width: 93%;
  margin: 0 auto;
  .an{
    margin: 32px 0 15px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: flex-start;
    .an-item{
      width: 81px;
      background: #515F73;
      opacity: 1;
      border-radius: 6px;
      font-size: 15px;
      color: white;
      text-align: center;
      line-height: 27px;
      letter-spacing: 3px;
      margin-left: 10px;
      &:nth-child(1){
        margin-left: 0;
      }
    }
  }
}
  ::v-deep .dv-scroll-board{
    flex: 4;
    .header{
      flex-wrap: nowrap;
      height: auto;
      .header-item{
        color: #00FFFF;
        text-align: center;
        border-right: 1px solid #7f7f7f;
      }
    }
    .rows{
      .row-item{
        .ceil{
          font-size: 15px;
          text-align: center;
          border-right: 1px solid #7f7f7f;

        }
      }
    }
  }

  .time-next{
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    color: white;

    p{
      margin: 0;
      font-size: 14px;
      display: flex;
      align-items: center;
      span{
        color: @major-color;
        font-size: 22px;
      }
    }
  }
.an-Select {
  background: #8A95A6 !important;
}
/deep/ .el-table{
  background: #ffffff00;
  border:0;
  tr{
    background: #ffffff00;
    color: white;
    &:hover>td{
      background: #ffffff00;
      color: white;
    }
    th{
      background: #ffffff22;
      border-bottom: 0;
    }
    .gutter {

    }
  }
}
/deep/.el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #ffffff22;
}
/deep/.el-table__row>td{
  border-bottom: none;
}
/deep/.el-table::before {//去掉最下面的那一条线
  height: 0px;
}
/*/deep/.el-table__body-wrapper::-webkit-scrollbar {
  width: 7px; !*滚动条宽度*!
  height: 7px; !*滚动条高度*!
  background-color: white;
}

!*定义滑块 内阴影+圆角*!
/deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(221, 222, 224); !*滚动条的背景颜色*!
}*/
/deep/.el-table__body-wrapper::-webkit-scrollbar-track{ -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1); background-color: #F5F5F5; border-radius: 0;}
/deep/.el-table__body-wrapper::-webkit-scrollbar{ width: auto; background-color: transparent;}
/deep/.el-table__body-wrapper::-webkit-scrollbar-thumb{ border-radius: 15px; background-color: #FFF; background-image: -webkit-linear-gradient(top, #e4f5fc 0%, #bfe8f9 50%, #9fd8ef 51%, #2ab0ed 100%);}
</style>
