<template>
<div>
    <div class="home-hider">
      <!--  当前参与分类用户数-->
      <div class="home-hider-number">
        <p style="width: 166px;">当前参与分类</p>
        <p class="one-svg">
<!--          <img src="../assets/img/ren.svg" alt="">-->
          共<span>{{usersdata}}</span>人</p>
      </div>
      <!--  标题-->
      <div class="home-hider-title">
        <dv-decoration-3 style="width:20%;height:30px;" />
        <h1>{{name}}垃圾分类大数据平台</h1>
        <img  @click="outlog" class="title-svg" src="../assets/img/title.svg" alt="">
        <dv-decoration-3 class="decoration3s" style="width:20%;height:30px;"  />
      </div>
      <!--  运行天数-->
      <div class="home-hider-days">
        <p>系统安全平稳运行</p>
        <p @click="fullScreen()">第<span>{{ days }}</span>天</p>
      </div>
    </div>
</div>

</template>

<script>

import { Getusers } from '@/api/home'
import { getLocal } from '@/utils/local'

export default {
  data () {
    return {
      usersdata: '',
      days: '',
      name: ''
    }
  },
  mounted () {
    this.getusersin()
    this.getdays()
    this.name = getLocal('district')
  },
  methods: {
    async getusersin () {
      const { data: { data } } = await Getusers()
      this.usersdata = data.user_total_num
    },
    getdays () {
      const timestamp = (Date.parse(new Date())) / 1000// 计算当前时间戳 (毫秒级)

      const time = getLocal('create_time') // 创建时间

      var date3 = (timestamp - time) * 1000 // 时间差的毫秒数

      this.days = Math.floor(date3 / (24 * 3600 * 1000))
    },
    outlog () {
      localStorage.setItem('large_token', '')
      this.$router.push('/login')
    },
    fullScreen () {
      var el = document.documentElement
      var rfs = el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullscreen
      if (typeof rfs !== 'undefined' && rfs) {
        rfs.call(el)
      }
    }
  }

}
</script>

<style lang="less" scoped >
@import '~@/assets/css/index.less';
.home-hider{
  width: 100%;
  height: 120px;
  z-index: 3;
  display: flex;
  box-shadow: 0px 83px 154px 25px #052957;
  background: url(../assets/img/back.png) no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  position: relative;
  .home-hider-number, .home-hider-days{ // 当前参与分类用户数/运行天数
    flex: 1;
    text-align: center;
    font-size: 18px;
    color: @text-color;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: flex-end;
    z-index: 2;
    p{
      margin: 0;
      letter-spacing: 1.2px;
      font-size: @fontsize;
      font-weight: bold;
      margin-right: 16%;
      line-height: 32px;
      span{
        color: @major-color;
        font-size: @spanfontsize;
      }
    }
    .one-svg{
      width: 166px;
      display: flex;
      justify-content: center;
      img{
        width: 34%;
      }
    }
  }
  .home-hider-title{  //标题
    flex: 6;

    color: @text-color;
    position: relative;
    h1{
      text-align: center;
      font-size: 46px;
      margin-top: 40px;
      margin-bottom: 17px;

    }
    .title-svg{
      position: absolute;
      bottom: 0;
      width: 90%;
      left: 50%;
      transform: translateX(-50%);
    }
    .dv-decoration-3{
      position: absolute;
      bottom: 9%;
    }
    .decoration3s{
      right: 0;
    }
  }
  .home-hider-days{ //运行天数
    flex: 1;

  }

}
</style>
