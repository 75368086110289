import axios from "./index";

// 获取垃圾房小区信息
export const GetArea = params => {
  return axios.request({
    url: "/chamber/statistic",
    method: "get",
    params
  });
};
