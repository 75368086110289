// logong
export const steLoading = (data) => {
  data.showLoading({
    text: 'loading',
    color: '#00FFFF',
    textColor: '#fff',
    maskColor: 'rgba(255, 255, 255, 0)',
    zlevel: 0
  })
}
