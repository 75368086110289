<template>
  <div class="com-father">
    <div ref="ranking_ref" class="com-ranking">
      这是ra
    </div>
    <!--    <p v-if="zertype" class="p-ze">暂无数据</p>-->
    <p class="paimig">{{ titles }}</p>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { GetCommunity, GetPersonal } from '@/api/home'
import { steLoading } from '@/utils/loading'
export default {
  data() {
    return {
      zertype: false,
      chartInstance: null, // 初始化的对象
      allData: [], // 获取的数据
      standardsize: 1, // 全局标准值
      titles: "社区排名"
    }
  },
  mounted() {
    this.initChart() // 调用初始化
    // this.getData() // 获取服务器数据
    window.addEventListener('resize', this.screenAdapter)
    // 屏幕适配
    this.screenAdapter()
  },
  destroyed() {
    // 移除监听
    window.removeEventListener('resize', this.screenAdapter)
  },
  methods: {
    // 初始化
    initChart() {
      this.chartInstance = echarts.init(this.$refs.ranking_ref, 'walden')
      // 对图表初始化配置控制
      const initOption = {
        // title: { // 标题设置
        //   text: '社区排名',
        //   textStyle: {
        //
        //     fontWeight: 'bold'
        //   },
        //   left: '3%',
        //   top: '3%'
        // },
        grid: { // 坐标轴配置
          top: '30%',
          left: '3%',
          right: '6%',
          bottom: '3%',
          containLabel: true // 包含文字

        },
        xAxis: {
          type: 'value',
          show: false

        },
        yAxis: [
          {
            type: 'category',
            axisTick: 'none',
            axisLine: 'none',
            splitLine: {
              show: false
            },

            inverse: true

          }, {
            axisTick: 'none',
            axisLine: 'none',
            type: 'category',
            splitLine: {
              show: false
            },
            inverse: true

          }],
        series: [{

          type: 'bar',
          showBackground: true,

          backgroundStyle: {
            color: 'rgba(180, 180, 180, 0.2)',

            borderType: 'solid',
            borderColor: '#828588',
            borderWidth: 1
          },
          barGap: '100%',
          itemStyle: {

            // 指明颜色渐变的方向
            // 不同百分比的值
            color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
              offset: 0,
              color: '#29ABE2'
            }, {
              offset: 1,
              color: '#00FFFF'
            }])
          }

        }]
      }
      this.chartInstance.setOption(initOption)
    },
    // 排序方法
    compare(namse) {
      return (obj1, obj2) => {
        const val1 = obj1[namse] * 1
        const val2 = obj2[namse] * 1
        if (val1 < val2) {
          return 1
        } else if (val1 > val2) {
          return -1
        } else {
          return 0
        }
      }
      // console.log(rr.sort(this.compare('value'))) 调用方式
    },
    // 获取服务器数据
    async getData(parameter, name) {
      this.zertype = false
      // 获取排名数据
      steLoading(this.chartInstance)
      if (name === '小区') {
        this.titles = "用户排名"
        const { data: { data: res } } = await GetPersonal(parameter)
        // console.log(res.data.length)
        this.allData = []
        if (res.data.length === 0) {
          this.chartInstance.clear()
          this.zertype = true
        } else {
          res.data.forEach(item => {
            var datat = { community: '', weight: '' }
            datat.community = item.username
            datat.weight = parseInt(item.recycling_weight)
            this.allData.push(datat)
            this.allData.splice(5)
            this.initChart()
            this.updataChart()
            this.screenAdapter()
          })
        }

        // this.allData = data.data
      } else {
        this.titles = "社区排名"
        const { data } = await GetCommunity(parameter)
        const newarr = data.data
        console.log(newarr)
        this.allData = newarr.sort(this.compare('weight')).slice(0, 5)
        console.log(this.allData)
        this.initChart()
        this.updataChart()
        this.screenAdapter()
      }
      this.chartInstance.hideLoading()
    },
    // 更新图表
    updataChart() {
      const sellNames = this.allData.map(item => {
        if (item.community.length > 6) {
          item.community = item.community.substr(0, 5)
        }
        return item.community
      })
      const sellValue = this.allData.map(item => {
        return item.weight
      })
      const selltext = this.allData.map(item => {
        return `${item.weight}Kg`
      })
      const DataOption = {
        yAxis: [
          {
            data: sellNames

          },
          {
            data: selltext

          }],
        series: [{
          data: sellValue
        }]
      }
      this.chartInstance.setOption(DataOption)
    },
    // 屏幕适配
    screenAdapter() {
      this.standardsize = this.$refs.ranking_ref.offsetWidth / 335
      const adapterOption = {
        title: { // 标题设置

          textStyle: {
            fontSize: 16 * this.standardsize

          }

        },

        yAxis: [
          {

            axisLabel: {
              fontSize: 13 * this.standardsize,
              margin: 13 * this.standardsize
            }

          }, {

            axisLabel: {
              fontSize: 13 * this.standardsize,
              margin: 13 * this.standardsize
            }

          }],
        series: [{

          barWidth: 12 * this.standardsize,
          backgroundStyle: {

            borderRadius: 12 * this.standardsize / 2

          },

          itemStyle: {
            borderRadius: 12 * this.standardsize / 2

          }

        }]
      }
      this.chartInstance.setOption(adapterOption)
      // 手动调用图表对象resize
      this.chartInstance.resize()
    }
  }
}
</script>

<style scoped>
.com-father {
  position: relative;
}

.p-ze {
  position: absolute;
  top: 37%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  z-index: 999999999999;
}

.paimig {
  position: absolute;
  left: 4%;
  top: -7%;
  font-size: 20px;
  color: #00fbfb;
  font-weight: bold;
}
</style>
