import axios from './index'

// 登录
export const LoginPost = data => {
  return axios.request({
    url: '/login',
    method: 'post',
    data
  })
}
