<template>
  <div class="com-father">
    <div class="gandi">
    <div ref="recovery_ref" class="com-recovery">
    </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { GetRecycle } from '@/api/home'
import { steLoading } from '@/utils/loading'
import { godou } from '@/utils/local'

export default {
  data () {
    return {
      chartInstance: null, // 初始化的对象
      allData: null, // 获取的数据
      standardsize: 1 // 全局标准值
    }
  },
  mounted () {
    this.initChart() // 调用初始化
    // this.getData() // 获取服务器数据
    window.addEventListener('resize', this.screenAdapter)
    // 屏幕适配
    this.screenAdapter()
  },
  destroyed () {
    // 移除监听
    window.removeEventListener('resize', this.screenAdapter)
  },
  methods: {
    // 初始化
    initChart () {
      this.chartInstance = echarts.init(this.$refs.recovery_ref, 'walden')

      // 对图表初始化配置控制
      const initOption = {
        color: ['#ED721D'],
        title: { // 标题设置
          text: '减量统计',
          textStyle: {
            fontWeight: 'bold'
          },
          left: '3%',
          top: '3%'
        },
        grid: { // 坐标轴配置
          top: '26%',
          left: '5%',
          right: '6%',
          bottom: '15%',
          containLabel: true // 包含文字

        },

        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          // inverse: true,
          splitLine: {
            show: false
          },

          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: [
          {
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                color: ['#3A4755'],
                width: 1,
                type: 'dotted'
              }
            }

          },
          {
            type: 'value',
            splitLine: {
              show: false
            }
          }
        ],
        series: [
          {
            name: '回收重量',
            data: [120, 200, 150, 80, 70, 110, 130],
            type: 'bar',
            barMaxWidth: 10,
            itemStyle: {
              color: '#079DB5' // 柱的颜色
            }
          },
          {
            // name: '下单量',
            name: '预约量',
            type: 'line',
            yAxisIndex: 1,
            symbol: 'none', // 去掉折线上的小圆点
            data: [120, 200, 150, 80, 70, 110, 130],
            lineStyle: {
              color: '#ED721D',
              type: 'solid'
            }
          }
        ]
      }
      this.chartInstance.setOption(initOption)
    },
    // 获取服务器数据
    async getData (parameter) {
      steLoading(this.chartInstance)

      // 获取排名数据
      const { data } = await GetRecycle(parameter)
      // console.log(data)

      this.allData = data.data

      this.allData.total = godou(this.allData.total)
      this.allData.count = godou(this.allData.count)

      this.$emit('update', this.allData)
      this.chartInstance.hideLoading()
      this.updataChart()
    },
    // 更新图表
    updataChart () {
      const DataOption = {
        xAxis: {
          data: this.allData.x
        },
        series: [
          {
            data: this.allData.y1
          },
          {
            data: this.allData.y2
          }
        ]
      }
      this.chartInstance.setOption(DataOption)
    },
    // 屏幕适配
    screenAdapter () {
      this.standardsize = this.$refs.recovery_ref.offsetWidth / 335
      const adapterOption = {
        title: { // 标题设置
          textStyle: {
            fontSize: 16 * this.standardsize
          }
        },

        xAxis: {
          nameTextStyle: {
            fontSize: 11 * this.standardsize
          }

        },
        yAxis: [
          {

            nameTextStyle: {
              fontSize: 11 * this.standardsize
            }
          },
          {

            nameTextStyle: {
              fontSize: 11 * this.standardsize
            }
          }
        ]
      }

      this.chartInstance.setOption(adapterOption)
      // 手动调用图表对象resize
      this.chartInstance.resize()
    }
  }
}
</script>

<style scoped lang="less">
.gandi{
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
