
import Home from '@/views/Home.vue'
import Login from '@/views/login.vue'
import RecoveryVolumePage from '@/views/RecoveryVolumePage'
import Communitydetails from '@/views/Communitylist'
import Rankingdetails from '@/views/Rankingdetails'
export default [

  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '美家分类-垃圾分类数据大屏'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: '美家分类-登录'
    }
  },
  {
    path: '/rv',
    name: 'RecoveryVolumePage',
    component: RecoveryVolumePage
  },
  {
    path: '/communitydetails',
    name: 'Communitydetails',
    component: Communitydetails,
    meta: {
      title: '美家分类-小区列表'
    }
  },
  {
    path: '/rankingdetails',
    name: 'Rankingdetails',
    component: Rankingdetails,
    meta: {
      title: '美家分类-小区排行'
    }
  },
  {
    path: '/assessment',
    name: 'Assessment',
    component: () => import('@/views/Assessment'),
    meta: {
      title: '美家分类 -考核内容'
    }
  },
  {
    path: '/equipmenthealth',
    name: 'Equipmenthealth',
    component: () => import('@/views/Equipmenthealth'),
    meta: {
      title: '美家分类-设备健康'
    }
  },
  {
    path: '/ceshi',
    name: 'Ceshi',
    component: () => import('@/views/ceshi'),
    meta: {
      title: '美家分类-设备健康'
    }
  }

]
