<template>

<!--1232131-->
  <div class="com-father">
  <div class="login">
    <div class="logcont">
      <div class="loginimg">
        <img style="width: 100%;" src="../assets/img/login/left.jpg" alt="">
      </div>
      <div class="loginfrom">
        <transition name="el-zoom-in-center" mode="out-in">
          <el-form v-show="shukong"  :rules="rules"  ref="loginFprmRef" :model="fromData" label-width="0px">
            <h2>垃圾分类大数据平台</h2>
            <p class="engyun">BIG DATA PLATFORM FOR GARBAGE SORTING</p>
            <el-form-item prop="username">
              <el-input  prefix-icon="el-icon-user-solid"  placeholder="请输入账号" v-model="fromData.username"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input  prefix-icon="el-icon-lock"  placeholder="请输入密码" v-model="fromData.password"  show-password @keyup.enter.native="login"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="success" @click="login">登录</el-button>
            </el-form-item>

          </el-form>
        </transition>
        <transition name="el-zoom-in-center" mode="out-in">
<!--                    二维码部分-->
                    <div v-show="!shukong" class="makuang">
                      <img class="erweimade" src="../assets/img/login/557.png" alt="">
                      <p class="matext">扫码查看美家月报</p>
                    </div>

        </transition>
        <img @click="huanzhan" class="huan-img" :src="shukong?require('../assets/img/login/5.svg'):require('../assets/img/login/4.svg')" alt="">
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { LoginPost } from '@/api/user'
import { Message } from 'element-ui'

export default {
  data () {
    return {
      shukong: true,
      fromData: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名称', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    huanzhan () {
      this.shukong = !this.shukong
    },
    login () {
      this.$refs.loginFprmRef.validate(async valid => {
        if (!valid) return false
        const { data: res } = await LoginPost(this.fromData)
        if (res.code !== 200) return Message.error('登录失败')
        Message.success('登录成功')
        localStorage.setItem('large_token', res.data.token)
        localStorage.setItem('zoom', res.data.zoom)
        localStorage.setItem('centre', res.data.centre)
        localStorage.setItem('district', res.data.district)
        localStorage.setItem('area_id', res.data.area_id)
        localStorage.setItem('create_time', res.data.create_time)
        // console.log(12)
        this.$router.push('/')
      })
    }
  }

}
</script>

<style lang="less" scoped>

.login{
  position: relative;
  background: url("../assets/img/login/bei.jpg") no-repeat;
  background-position: center  center;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  .logcont{
    width: 434px;
    height: 415px;
    background: rgba(255,255,255,0.1);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: white;
    width: 867px;
    height: 487px;
    display: flex;
    .makuang{
      position: absolute;
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      .erweimade{
        width: 180px;
      }
      .matext{
        color: #3179EA;
        text-align: center;
        margin-top: 15px;
        margin-bottom: 15px;
        font-weight: bold;
      }
    }
   .loginimg{
     width: 418px;
     height: 100%;
   }
    .loginfrom{
      overflow: hidden;
      width: 867-418px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      height: 100%;
      .huan-img{
        position: absolute;
        width: 26%;
        right: 0;
        bottom: 0;
      }
      .el-form{
        width: 100%;
        height: 100%;
        h2{
          height: 28px;
          font-size: 28px;
          color: #383838;
          text-align: center;
          margin-top: 78px;
          margin-bottom: 15px;
          font-weight: bold;

        }
        .engyun{
          height: 12px;
          font-size: 12px;
          line-height: 15px;
          color: #C1C1C1;
          text-align: center;
          margin-top: 0;
          margin-bottom: 40px;
          font-weight: bold;
        }
        ::v-deep .el-form-item{
          width: 60%;
          margin: 5% auto;
          .el-input__inner{
            border: 2px solid #3179EA;
            border-radius: 25px;
            background: #fff;
            color: #262424;
          }
        }
        .el-button{
          border-radius: 25px;
          background: #3179EA;
          width: 100%;
          margin: 0 auto;
          border: 0;
          margin-top: 30px;
        }
        ::v-deep .el-input__icon {
          color: #3179ea;
        }
      }

    }
  }
}

</style>
