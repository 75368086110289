<template>
  <div class="com-father">
    <div class="home-cntent">
      <!--      头部标题-->
      <Hiders></Hiders>
      <!--      图表区域-->
      <div class="community-cons">
        <div class="gohome"><span class="iconfont icon-fangzi01-copy iconfontbac"></span><a href="/">返回首页</a></div>

      <div class="community-item" v-for="item in allData" :key="item.id">
        <div class="list-top">
          <p class="top-lift">{{item.name}}</p>
          <p class="top-right" ><a style="cursor:pointer;" @click="gohome(item.id,item.centre)" >查看小区详情>></a></p>
        </div>
        <div class="list-bottom">
          <p>
            <span>入住户数</span>

            <span class="blue"><span>{{ item.total_num_house }}</span>户</span></p>
          <p><span>入住率</span>

            <span class="jv"><span>{{ item.occupancy_rate.substring(0,item.occupancy_rate.indexOf('.')) }}</span>%</span></p>
          <p><span>注册户数</span>

            <span class="blue"><span>{{ item.register_house_num }}</span>户</span></p>
          <p><span>注册率</span>
            <span class="jv"><span>{{ parseInt(item.register_house_ratev) }}</span>%</span></p>
        </div>
      </div>
      </div>

      <!--      图表区域结束-->
    </div>

  </div>
</template>

<script>
import Hiders from '@/components/Hiders'
import { Getcommunity, Getparticipate, Getraknlist } from '@/api/rankingdatails'

export default {
  data () {
    return {
      allData: null,
      funname:'zi'

    }
  },
  components: {
    Hiders,
  },
  mounted () {
    this.getData()
  },
  methods: {
    async getData (parameter) {
      const { data } = await Getcommunity(parameter)
      // console.log(data.data.info)
      this.allData = data.data
      this.allData.forEach(item => {
        item.register_house_ratev = item.register_house_rate * 100
      })
    },
    gohome(id,centre){

      if(centre.length>1){
        this.$router.push({
          path: '/',
          query: {
            id,
            centre
          }
        })
      }else {
        alert('小区数据维护中')
      }

    }

  }
}
</script>
<style lang="less" scoped>
@import '~@/assets/css/index.less';
#app{

.com-father{
  background: url("../assets/img/back2.jpg") no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  .home-cntent{
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    .m-map{
      position: absolute;
      z-index: 1;
    }
    .home-echers{
      width: 100%;
      height: 100%;

      position: relative;
      .echers-left , .echers-right{
        width: @show-whith;
        background: #00000073;
        position: absolute;
        top: 0;
        border-top: 3px solid #ffffff4d;
        z-index: 4;

      }

      &:before{
        content: '';
        width: 100%;
        position: absolute;
        bottom: 0;
        z-index: 2;
        box-shadow:0px 37px 178px 30px #052957;
      }
    }
  }
.community-cons{
  padding: 0 3.2%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
  overflow: auto;
  max-width: 1920px;
  margin: 3% auto;
  z-index: 3;
  .community-item{
    width: 307px;
    margin-left: 20px;
    margin-top: 30px;
    height: 132px;
    background: rgba(255, 255, 255, 0.1);
    border: 2px solid #ffffff80;
    border-radius: 12px;
    color: white;
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    p{
      margin: 0;

    }
    .list-top{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid #ffffff80;
      padding-bottom: 9px;
      margin-top: 14px;
      a{
        color: white;
        text-decoration: none;
      }
      .top-lift{
        font-size: 21px;
        font-weight: bold;
        line-height: 24px;
      }
      .top-right{
        font-size: 14px;
        line-height: 23px;
      }
    }
    .list-bottom{
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-top: 8px;
      p{
        width: 74px;
        height: 60px;
        background: rgba(255,255,255,0.2);
        border-radius: 7px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        align-content: center;
        span{
          font-size: 14px;
          color: #FFFFFF;
        }
        .jv{
          color: #ED721D;
          span{
            color: #ED721D;
            font-size: 21px;
          }
        }
        .blue{
          color: #00FFFF;
          span{
            color: #00FFFF;
            font-size: 20px;
          }
        }
      }
    }
  }


  .community-item:nth-child(5n - 3) {
    margin-left: 0;
  }
}
}
}
.detailedtext::-webkit-scrollbar-track{ -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1); background-color: #F5F5F5; border-radius: 10px;}
.detailedtext::-webkit-scrollbar{ width: 10px; background-color: transparent;}
.detailedtext::-webkit-scrollbar-thumb{ border-radius: 10px; background-color: #FFF; background-image: -webkit-linear-gradient(top, #e4f5fc 0%, #bfe8f9 50%, #9fd8ef 51%, #2ab0ed 100%);}
.detailedtext::-webkit-scrollbar { width: 0 !important }
</style>
