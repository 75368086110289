<template>
  <div class="mapMark" id="markBox">
    <div class="closeDia" @click="closeDia">
      <img src="../assets/img/关闭.png" alt="" />
    </div>
    <div style="color:#fff;font-size: 30px;margin: 20px;text-align: center;">
      卧龙山庄01号生活垃圾分类投放点
    </div>
    <div class="content">
      <div>
        <canvas ref="game" id="canvs"></canvas>
        <!-- <img
          src="../assets/img/lajifang.png"
          style="width:70%;margin:-50px 0px 0 30px"
          alt=""
        /> -->
        <div style="margin-top:20px;color:#fff;text-align: left;margin-left: 30px;">
          今日投递次数 <span style="color:#00FFFF;font-size: 30px;">93</span> /
          今日参与用户数 <span style="color:#FAC858;font-size: 30px;">84</span>
        </div>
      </div>
      <div class="right">
        <div style="margin-bottom:30px">
          <div style="color:#fff;font-size: 20px;margin:0 0 20px 20px;">
            近七天参与投递用户数
          </div>
          <img src="../assets/img/bar-y-category (3).png" alt="" />
        </div>

        <div>
          <div style="color:#fff;font-size: 20px;margin:0 0 20px 20px;">
            近七天垃圾投递重量统计
          </div>
          <img src="../assets/img/line-smooth (2).png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<!-- 待删除 -->
<script>
import * as Three from "three";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
let scene = null,
  camera = null,
  renderer = null,
  model = null,
  controls = null,
  clickObjects = new Three.Group(),
  mesh = null;
export default {
  inject: ["reload"],
  data() {
    return {
      clickObjects: new THREE.Group(),
      width: window.innerWidth + 100, //窗口宽度
      height: window.innerHeight - 100 //窗口高度
      // model: null,
      // controls: null,
      // camera: null,
      // renderer: null,
      //   scene: null
    };
  },
  created() { },
  mounted() {
    // this.init3dload();
    this.init();
    // this.animate();
    console.log(scene, "scene");
  },
  watch: {},
  methods: {
    closeDia() {
      try {
        let dom = document.getElementById("markBox");
        dom.style.display = "none";
        scene.clear();
        renderer.dispose();
        renderer.forceContextLoss();
        renderer.content = null;
        console.log(scene, "sceneafter");
        // cancelAnimationFrame(animationID); // 去除animationFrame
        // let gl = renderer.domElement.getContext("webgl");
        // gl && gl.getExtension("WEBGL_lose_context").loseContext();
      } catch (e) {
        console.log(e);
      }
    },
    init() {
      //   let dom = document.getElementById("canvs");

      // 场景
      scene = new Three.Scene();
      // 添加相机
      camera = new Three.PerspectiveCamera(
        10,
        this.width / this.height,
        0.01,
        2000
      );
      //设置相机坐标
      camera.position.set(0.8, 0.5, 20);
      // camera.position.set(0, 0, 0);
      camera.lookAt(new Three.Vector3(0, 0, 0));
      //添加坐标系------------------------------------------------------------------------------------------
      //   scene.add(new Three.AxesHelper(5));
      // 创建轨道控制器 相机围绕模型看到的角度
      //   const OrbitControl = new OrbitControls(camera, renderer.domElement);
      //   // 设置轨道自然
      //   OrbitControl.enableDamping = true;
      //   // 设置惯性
      //   OrbitControl.update();
      controls = new OrbitControls(camera, this.$refs.game);
      let light = new Three.DirectionalLight("#ffffff", 3); //添加灯光
      light.position.set(100, 100, 100);
      light.lookAt(new Three.Vector3(0, 0, 0));
      scene.add(light); //点光源添加到场景中
      // 引入gltf
      const loader = new GLTFLoader();
      loader.load(
        "https://qiniu.haiwojiamei.com/trashbin_v2/scene.gltf",
        onLoad
      );
      function onLoad(obj) {
        obj.scene.scale.set(1, 1, 1);
        // obj.scene.scale.set(0.000036, 0.000036, 0.000036);
        obj.scene.position.copy(new Three.Vector3(-0.5, -0.5, 0));
        obj.scene.rotation.copy(new Three.Euler(0, Math.PI + 2.8, 0)); //旋转，没有需求改为0即可
        clickObjects.add(obj.scene);
        scene.add(clickObjects);
      }
      // 初始化渲染器
      //   renderer = new Three.WebGLRenderer({ antialias: true });
      renderer = new Three.WebGLRenderer({
        canvas: this.$refs.game,
        alpha: true, //是否背景可以透明
        antialias: true //抗拒齿
      });
      // 渲染器的大小
      renderer.setSize(this.width, this.height);
      // 将渲染器添加到页面
      //   dom.appendChild(renderer.domElement);
      function animate() {
        requestAnimationFrame(animate);
        renderer.render(scene, camera);
      }
      animate();
    }
  }
};
</script>
<style lang="less" scoped>
.mapMark {
  display: none;
  z-index: 99999999;
  position: fixed;
  width: 1200px;
  height: 600px;
  left: 0;
  right: 0;
  top: 8%;
  bottom: 0;
  margin: auto;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.7);
}

.mapMark .content {
  margin-top: 60px;
  /* width: ; */
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#canvs {
  width: 500px !important;
  height: 300px !important;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  border: 1px solid #fff;
  /* height: 100%; */
  margin: -50px 0px 0 30px;
}

.closeDia {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
