<template>
  <div class="m-map">
    <div id="ly_container" class="ly_container"></div>
  </div>
</template>

<script>
import { GetWei, GetMap, Getusergeo, GetValve } from "@/api/home";
import { getLocal } from "@/utils/local";
export default {
  name: "MapDrag",
  data() {
    return {
      mapName: null, // 地图实例
      houseData: null, // 小房子的数据
      buildingLayer: null, // 房子对象
      zoom: 14, // 缩放级别
      pitch: 0, // 俯仰角度
      center: [120.42862, 36.113496], // 中心点
      rotation: 0, // 旋转角度
      zhuanyunzhandata: [], // 转运站
      // 区域用来判断点击范围
      area: ["崂山区", "市南区", "市北区", "李沧区"],
      tongdianData: [],
      zide: 1, // 当前缩放级别
      numdata: null, // 桶点数据
      streetData: [], // 街道围点数据
      currentname: "", // 当前地区名称
      currentid: "", // 当前地区id
      chushi: {
        district: null,
        area_id: null,
        zoom: null,
        centre: null
      },
      paochu: [],
      poshss: [], // 勾画楼栋数据
      object3Dlayer: null, // 3d对象
      typen: false, // 缩放17的控制
      ddds: [], // 3d的存储用于删除
      rubbishHouse: null
    };
  },
  // 事件集合
  methods: {
    // 实例化地图
    initMap() {
      // 设置建筑物
      this.buildingLayer = new AMap.Buildings({
        zIndex: 130,
        merge: false,
        sort: false,
        zooms: [16, 20]
      });
      // console.log(this.houseData)
      this.buildingLayer.setStyle(this.houseData); // 此配色优先级高于自定义mapStyle
      this.buildingLayer.hide(); // 隐藏房子
      this.mapName = new AMap.Map("ly_container", {
        expandZoomRange: true,
        zooms: [3, 20], // 设置缩放级别范围 3-20 级
        resizeEnable: true, // 是否监控地图容器尺寸变化

        zoom: this.zoom, // 设置地图显示的缩放级别
        pitch: this.pitch, // 俯仰角度
        rotation: this.rotation, // 旋转角度
        showIndoorMap: false, // 是否在有矢量底图的时候自动展示室内地图areas
        showLabel: true,
        buildingAnimation: true, // 楼块出现是否带动画
        // mapStyle: 'amap://styles/6ccf413840217be91226c9c071c185c9',
        mapStyle: "amap://styles/48d92d582c02a4fac949442dc668078f",
        center: this.center, // 地图中心点坐标值
        features: ["bg", "point", "road"], // 设置地图上显示的元素种类
        viewMode: "3D", // 使用3D视图
        layers: [
          new AMap.TileLayer(),
          // 挂载房子
          this.buildingLayer
        ]
      });
      // 给gltf模型绑定事件
      this.mapName.on("click", ev => {
        console.log(this.object3Dlayer, "object3Dlayer");
        console.log(ev, "ev");
        console.log(this.mapName, "this.mapName");
        var pixel = ev.pixel;
        var px = new AMap.Pixel(pixel.x, pixel.y);
        var obj =
          this.mapName.getObject3DByContainerPos(
            px,
            [this.object3Dlayer],
            false
          ) || {};
        console.log(obj, "obj");
        console.log(this.rubbishHouse, "rubbishHouse");
        if (obj && obj.object) {
          // alert(1)
          var meshId = obj.object.id;
          if (this.rubbishHouse && this.rubbishHouse.layerMesh) {
            // alert(2)
            for (var i = 0; i < this.rubbishHouse.layerMesh.length; i++) {
              if (meshId === this.rubbishHouse.layerMesh[i].id) {
                alert(3);
                // return log.info("您点击了垃圾房模型！");
                console.log("您点击了垃圾房模型！");
              }
            }
          }
          // if (druckMeshes && druckMeshes.layerMesh) {
          //   for (var i = 0; i < druckMeshes.layerMesh.length; i++) {
          //     if (meshId === druckMeshes.layerMesh[i].id) {
          //       return log.info("您点击了小黄鸭模型！");
          //     }
          //   }
          // }

          // if (cityMeshes && cityMeshes.layerMesh) {
          //   for (var i = 0; i < cityMeshes.layerMesh.length; i++) {
          //     if (meshId === cityMeshes.layerMesh[i].id) {
          //       return log.info("您点击了陆家嘴模型！");
          //     }
          //   }
          // }
        }
      });
      this.mapName.on("zoomend", () => {
        var zoom = this.mapName.getZoom(); // 获取当前地图级别
        // console.log(zoom)

        // console.log(this.zide)
        // if (this.zide === 4 && zoom < 17.5) {
        //   this.mapName.clearMap()// 清除所以覆盖物
        //   this.fangzi()
        //   this.zide = 3
        //   this.ReRequest('18', '去详情')
        // }
        // if (this.zide === 3 && zoom < 15.5) {
        //   this.mapName.clearMap()// 清除所以覆盖物
        //   this.streetRange()
        //   this.zide = 2
        //   this.ReRequest({ area_id: '44535' }, '中韩街道') // 街道的ID
        // }
        // if (this.zide === 2 && zoom < 14) {
        //   this.mapName.clearMap()// 清除所以覆盖物
        //   this.fanwei()
        //   this.zide = 1
        //   this.ReRequest({ area_id: '44534' }, '崂山区')
        // }
        // 当进入界别是街道级别的
        // if (this.zide === '12' && zoom < 16) {
        //   // 区级账号
        // }
        if (this.zide === "16" && zoom < 16) {
          if (zoom < 15.5) {
            this.mapName.clearMap(); // 清除所以覆盖物
            this.remove3dfun(); // 移除3d
            this.streetRange(); // 显示街道
            return false;
          } else {
            this.remove3dfun(); // 移除3d
            this.mapName.clearMap(); // 清除所以覆盖物
            this.fangzi();

            this.ReRequest("18", "去详情");
            this.ReRequest({ area_id: this.currentid }, this.currentname);
          }
        }
        // 当进入界别是区级别的
        if (this.typen && zoom < 18) {
          this.qunide(); // 清除3d捅
          setTimeout(t => {
            this.ddds = [];
          }, 1000);
          this.mapName.clearMap(); // 清除所以覆盖物
          this.ReRequest("18", "去详情");
          this.fangzi();
          this.typen = false;
          this.ReRequest({ area_id: this.currentid }, this.currentname);
        }
        if (this.zide === "12" && zoom < 16) {
          if (zoom < 15) {
            if (zoom < 14.5) {
              this.ReRequest({ area_id: this.currentid }, this.currentname);
              return false;
            } else {
              this.mapName.clearMap(); // 清除所以覆盖物
              this.remove3dfun(); // 移除3d
              this.streetRange(); // 显示街道
            }
          } else {
            this.mapName.clearMap(); // 清除所以覆盖物
            this.remove3dfun(); // 移除3d
            this.ReRequest("18", "去详情");
            this.streetRange(); // 显示街道

            this.ReRequest({ area_id: this.currentid }, this.currentname);
          }
        }
      });
    },
    qunide() {
      this.ddds.forEach((item, i) => {
        this.object3Dlayer.remove(item);
      });
    },
    // 移除3d
    remove3dfun() {
      if (this.paochu) {
        this.paochu.forEach(item => {
          this.object3Dlayer.remove(item);
        });
      }
    },
    // 小房子部分
    fangzi() {
      // 获取缓存中的地区id不是中韩的话不显示楼栋
      var ids = getLocal("area_id");
      // console.log(ids)
      if (ids === "44535") {
        this.buildingLayer.show(); // 显示具体房子
      }

      this.buildingLayer.setStyle(this.houseData); // 此配色优先级高于自定义mapStyle

      // 添加 Object3DLayer 图层，用于添加 3DObject 对象
      var object3Dlayer = new AMap.Object3DLayer();
      this.mapName.add(object3Dlayer);
      this.houseData.areas.forEach(item => {
        if (item.building) {
          item.building.forEach(buildings => {
            buildings.color1 = item.color1;
            buildings.color2 = item.color2;
            buildings.color3 = item.color3;
            buildings.color4 = `#${item.color1.substr(2, 6)}`;
            buildings.height = item.height;
            this.poshss.push(buildings);
          });
        }
      });
      this.lizhu(this.poshss); // 调用自定义立柱
      this.houseData.areas.forEach(item => {
        // 小房子地块显示
        // eslint-disable-next-line no-new
        var fangziP = new AMap.Polygon({
          // 覆盖物>多边形
          bubble: true, // 是否将覆盖物的鼠标或touch等事件冒泡到地图上
          // fillColor: item.color3, // 地面颜色
          fillColor: "#00FFFF",
          fillOpacity: 0.4, // 多边形填充透明度
          strokeWeight: 1, // 线条宽度，单位：像素
          path: item.path, // 数据源
          extData: { projectId: item.id },
          map: this.mapName
        });
        var ellipTexts = new AMap.Text({
          text: item.name,
          verticalAlign: "top",
          position: item.centre,
          height: 100,
          style: {
            "background-color": "transparent",
            "-webkit-text-stroke": "white",
            "-webkit-text-stroke-width": "0",
            "text-align": "center",
            border: "none",
            color: "white",
            "font-size": "14px",
            "font-weight": 500
          },
          map: this.mapName
        });
        ellipTexts.on("click", item => {
          // 小区id
          var quid = fangziP.getExtData().projectId;
          console.log(quid,'quidquidquid');
          this.qunide(); // 移除3d
          this.ddds = [];
          this.ReRequest({ community_id: quid }, "小区"); // 小区的ID
          // console.log(quid + '房子的')
          // 缩放地图到合适的视野级别
          this.mapName.setFitView([fangziP]);
          this.mapName.setZoom(19);
          this.mapName.setPitch(60);
          this.typen = true;
        });
      });
    },
    lizhu(areas) {
      // 自定义房子
      // 添加 Object3DLayer 图层，用于添加 3DObject 对象
      this.object3Dlayer = new AMap.Object3DLayer();
      this.mapName.add(this.object3Dlayer);
      // ===
      areas.forEach(items => {
        var xinshuzu = JSON.parse(JSON.stringify(items.path));
        const neipao = new AMap.Object3D.Prism({
          path: xinshuzu.map(item => {
            return new AMap.LngLat(item[0], item[1]);
          }),
          height: items.height,
          color: items.color4 // 支持 #RRGGBB、rgb()、rgba() 格式数据
        });
        this.paochu.push(neipao);
        this.object3Dlayer.add(neipao);
      });
    },
    // 直接搜索小并显示的
    searchlook(e, t) {
      // 移除所有覆盖物
      // 缩放级别调整至小区
      // 显示小房子
      // 设置中心点

      if (t === "初始") {
        this.gataData();
        setTimeout(() => {
          // this.mapName.clearMap()// 清除所以覆盖物
          this.mapName.setZoomAndCenter(19, e.centre);
          this.tongdian(this.numdata);
          this.typen = true;
          // console.log(e)
        }, 3000);
      } else {
        this.mapName.clearMap(); // 清除所以覆盖物
        this.mapName.setZoomAndCenter(19, e.centre);
        this.fangzi();
        // console.log(e)
      }
    },

    // 标记桶点
    tongdian(num, name) {
      // console.log(num)
      this.qunide();
      this.numdata = num;

      var gltfObj = new AMap.GltfLoader();
      if (num.quadruple_buckets) {
        var forthurlDuck =
          "https://qiniu.haiwojiamei.com/trashbin_forth/scene.gltf?=211125";
        num.quadruple_buckets.forEach(item => {
          gltfObj.load(forthurlDuck, gltfDuck => {
            gltfDuck.setOption({
              position: item, // 必须
              scale: 10, // 非必须，默认1
              height: 0, // 非必须，默认0
              scene: 0 // 非必须，默认0
            });
            gltfDuck.rotateX(90);
            gltfDuck.rotateZ(-20);
            this.ddds.push(gltfDuck); // 把gltf放进去用于删除
            this.ddds.push(gltfDuck);
            this.object3Dlayer.add(gltfDuck);
          });
        });
      }

      if (num.double_buckets) {
        var twourlDuck = "https://qiniu.haiwojiamei.com/new_trash/2/scene.gltf";
        // var twourlDuck = 'https://qiniu.haiwojiamei.com/trash_bin_second/scene.gltf?=211125'
        num.double_buckets.forEach(item => {
          gltfObj.load(twourlDuck, gltfDuck => {
            gltfDuck.setOption({
              position: item, // 必须
              scale: 8, // 非必须，默认1
              height: 0, // 非必须，默认0
              scene: 0 // 非必须，默认0
            });
            gltfDuck.rotateX(90);
            gltfDuck.rotateZ(-20);
            this.ddds.push(gltfDuck); // 把gltf放进去用于删除
            this.object3Dlayer.add(gltfDuck);
          });
        });
      }
      // 宣传栏
      if (num.ad) {
        var adlDuck = "https://qiniu.haiwojiamei.com/advise/scene.gltf?=211125";
        num.ad.forEach(item => {
          gltfObj.load(adlDuck, gltfDuck => {
            gltfDuck.setOption({
              position: item, // 必须
              scale: 20, // 非必须，默认1
              height: 0, // 非必须，默认0
              scene: 0 // 非必须，默认0
            });
            gltfDuck.rotateX(90);
            gltfDuck.rotateZ(-20);
            this.ddds.push(gltfDuck); // 把gltf放进去用于删除
            this.object3Dlayer.add(gltfDuck);
          });
        });
      }
      // console.log(num)
      // 服务站
      if (num.service_station) {
        // console.log(num.service_station)
        var fuwulDuck = "https://qiniu.haiwojiamei.com/service/scene.gltf";
        num.service_station.forEach(item => {
          gltfObj.load(fuwulDuck, gltfDuck => {
            gltfDuck.setOption({
              position: item.centre, // 必须
              scale: 90, // 非必须，默认1
              height: 0, // 非必须，默认0
              scene: 0 // 非必须，默认0
            });
            gltfDuck.rotateX(90);
            gltfDuck.rotateZ(-20);
            this.ddds.push(gltfDuck); // 把gltf放进去用于删除
            this.object3Dlayer.add(gltfDuck);
          });
        });
      }
      // 垃圾房、如果时新的则判断id

      var urlDuck = "https://qiniu.haiwojiamei.com/trashbin_v2/scene.gltf";
      // console.log(num)
      if (
        name !== "转运" &&
        num.trash_chamber !== undefined &&
        num.trash_chamber !== null &&
        num.trash_chamber.length > 0
      ) {
        if (num.trash_chamber[0].data !== undefined) {
          // 判断里面时对象的时候
          num.trash_chamber.forEach(item => {
            gltfObj.load(urlDuck, gltfDuck => {
              this.rubbishHouse = gltfDuck;
              gltfDuck.setOption({
                position: item.data, // 必须
                scale: 30, // 非必须，默认1
                height: 10, // 非必须，默认0
                scene: 0 // 非必须，默认0
              });
              gltfDuck.rotateX(90);
              gltfDuck.rotateY(0);
              gltfDuck.rotateZ(item.angle * 1);
              this.ddds.push(gltfDuck); // 把gltf放进去用于删除
              this.object3Dlayer.add(gltfDuck);
            });
          });
        } else {
          num.trash_chamber.forEach(item => {
            gltfObj.load(urlDuck, gltfDuck => {
              this.rubbishHouse = gltfDuck;
              gltfDuck.setOption({
                position: item, // 必须
                scale: 30, // 非必须，默认1
                height: 0, // 非必须，默认0
                scene: 0 // 非必须，默认0
              });
              gltfDuck.rotateX(90);
              gltfDuck.rotateZ(-20);
              this.ddds.push(gltfDuck); // 把gltf放进去用于删除
              this.object3Dlayer.add(gltfDuck);
            });
          });
        }
      }
      // 转运站
      if (name === "转运") {
        var zhuanyunlDuck =
          "https://qiniu.haiwojiamei.com/transport_station_v4/scene.gltf";
        num.forEach(item => {
          // console.log(item.geo)
          gltfObj.load(zhuanyunlDuck, gltfDuck => {
            gltfDuck.setOption({
              position: item.geo, // 必须
              scale: 90, // 非必须，默认1
              height: 0, // 非必须，默认0
              scene: 0 // 非必须，默认0
            });
            gltfDuck.rotateX(90);
            gltfDuck.rotateZ(-20);
            this.ddds.push(gltfDuck); // 把gltf放进去用于删除
            this.object3Dlayer.add(gltfDuck);
          });
        });
      }
    },
    // 街道勾画
    streetRange() {
      this.mapName.setPitch(40);
      this.mapName.setRotation(45);
      this.streetData.forEach(item => {
        var streetpolygon = new AMap.Polygon({
          path: item.geo,
          borderWeight: 1, // 线条宽度，默认为 1
          strokeColor: "#4e75e9", // 线条颜色
          fillColor: "#00FFFF",
          strokeWeight: 2,
          fillOpacity: 0,
          zIndex: 50,
          extData: { name: item.name }
        });
        // 设置文字
        var streetellipText = new AMap.Text({
          text: item.name,
          verticalAlign: "top",
          position: item.centre,
          height: 100,
          style: {
            "background-color": "transparent",
            "-webkit-text-stroke": "white",
            "-webkit-text-stroke-width": "0",
            "text-align": "center",
            border: "none",
            color: "white",
            "font-size": "25px",
            "font-weight": 600
          },
          map: this.mapName
        });
        this.mapName.add(streetpolygon);
        streetpolygon.on("mouseover", m => {
          // 鼠标移入事件
          streetpolygon.setOptions({ fillOpacity: 0.4 });
        });
        streetpolygon.on("mouseout", m => {
          // 鼠标离开事件
          streetpolygon.setOptions({ fillOpacity: 0 });
        });
        streetellipText.on("click", m => {
          // 鼠标点击事件
          this.ReRequest({ area_id: item.id }, item.name); // 街道的ID
          this.dianjiquyu(17, item.centre); // 设置中心点及缩放
          this.mapName.clearMap(); // 清除所以覆盖物
          this.gataData();
        });
      });
    },
    // f范围勾勒
    fanwei() {
      //       绘制青岛市各区
      this.area.forEach(iite => {
        new AMap.DistrictSearch({
          subdistrict: 0, // 返回下一级行政区
          extensions: "all", // 返回行政区边界坐标组等具体信息
          level: "province" // 查询行政级别为 市
        }).search(iite, (st, res) => {
          let bounds;

          // 判断是不是崂山，如果是去掉小岛
          res.districtList[0].name === "崂山区"
            ? (bounds = res.districtList[0].boundaries[20])
            : (bounds = res.districtList[0].boundaries);

          var polygon = new AMap.Polygon({
            path: bounds,
            borderWeight: 1, // 线条宽度，默认为 1
            strokeColor: "#4e75e9", // 线条颜色
            fillColor: "#00FFFF",
            strokeWeight: 2,
            fillOpacity: 0,
            zIndex: 50,
            extData: { name: res.districtList[0].name }
          });
          var polygonName = polygon.getExtData().name; // 获取点击的名称
          var polygonCenter = res.districtList[0].center; // 获取点击的中心点
          // 设置文字
          var ellipText = new AMap.Text({
            text: res.districtList[0].name,
            verticalAlign: "top",
            position: polygonCenter,
            height: 100,
            style: {
              "background-color": "transparent",
              "-webkit-text-stroke": "white",
              "-webkit-text-stroke-width": "0",
              "text-align": "center",
              border: "none",
              color: "white",
              "font-size": "25px",
              "font-weight": 600
            },
            map: this.mapName
          });
          this.mapName.add(polygon);
          polygon.on("mouseover", m => {
            // 鼠标移入事件
            // console.log(polygonName + '在里面')
            polygon.setOptions({ fillOpacity: 0.4 });
          });
          polygon.on("mouseout", m => {
            // 鼠标离开事件
            // console.log(polygonName + '离开了')
            polygon.setOptions({ fillOpacity: 0 });
          });
          polygon.on("click", m => {
            // 鼠标点击事件
            if (polygonName === "崂山区") {
              this.ReRequest({ area_id: "44534" }, "崂山区");
              this.dianjiquyu(17, [120.455671, 36.12499]); // 设置中心点及缩放
              this.mapName.clearMap(); // 清除所以覆盖物
              this.getusergeo();
            }
            if (polygonName === "李沧区") {
              alert("暂未开放");
            }
            if (polygonName === "市南区") {
              alert("暂未开放");
            }
            if (polygonName === "市北区") {
              alert("暂未开放");
            }
          });
        });
      });
    },
    // 启用地图范围限定
    SettinRange() {
      var bounds = this.mapName.getBounds();
      // console.log(bounds)
      this.mapName.setLimitBounds(bounds);
    },
    async gataData() {
      // 获取小区围点
      const { data: res } = await GetMap(); // 请求小区的数据
      this.houseData = res.data;

      this.houseData.hideWithoutStyle = true;
      this.fangzi();
      // 获取转运站
      const {
        data: { data: ree }
      } = await GetValve();
      this.zhuanyunzhandata = ree;
      this.tongdian(this.zhuanyunzhandata, "转运");
      // 加载转运站
    },
    // 获取初始数据
    getloytdata() {
      this.chushi.district = getLocal("district");
      this.chushi.area_id = getLocal("area_id");
      this.chushi.zoom = getLocal("zoom");
      this.chushi.centre = getLocal("centre");
    },
    // 获取账户下所有的街道
    async getusergeo() {
      // 初始化渲染地图
      var zoomLog = getLocal("zoom");
      var centres = getLocal("centre");
      var centre = centres.split(",");
      if (this.chushi.area_id === "44545") {
        zoomLog = 16;
      }
      this.zide = zoomLog;
      this.initMap();
      // 判断进来的是不是区级账号显示街道
      if (zoomLog < 16) {
        // 如果是街道上级权限账号则显示区
        this.mapName.setCenter(centre);
        const { data } = await Getusergeo();
        this.streetData = data.data;

        this.streetRange();
        this.currentname = "崂山区";
        this.currentid = this.chushi.area_id;
      } else {
        // 否则直接显示街道中小区
        this.gataData();
        this.mapName.setZoom(16);
        this.mapName.setCenter(centre);
        this.mapName.setPitch(40);
        this.mapName.setRotation(45);
        const { data } = await Getusergeo();
        this.streetData = data.data;
        this.currentname = this.streetData[0].name;
        this.currentid = this.streetData[0].id;
      }
    },
    // 点击覆盖物执行的事件
    /*
     * name 是区域名称
     * */
    dianjiquyu(zoom, center) {
      // 设置中心点和缩放

      this.mapName.setZoomAndCenter(zoom, center);
      // polygon.setOptions({ fillOpacity: 0 })
      // polygon.hide() // 隐藏区级范围
    },
    // 组件通信
    ReRequest(nume, name) {
      /*
      nume 区域id
      name 区域名字
      */
      this.$emit("onreChange", nume, name);
    }
  },
  created() { },
  // 初始化
  mounted() {
    this.getloytdata();
    // 加载数据
    this.getusergeo();
  }
};
</script>

<style>
.m-map {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#ly_container {
  width: 100%;
  height: 100%;
}

.custom-content-marker {
  position: relative;
  width: 100px;
}

.custom-content-marker img {
  width: 100%;
  height: 100%;
}
</style>
