<template>
  <div class="com-father">
    <div ref="usergrowth_ref" class="com-usergrowth">
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { GetActivit } from '@/api/home'
import { steLoading } from '@/utils/loading'

export default {
  data () {
    return {
      chartInstance: null, // 初始化的对象
      allData: null, // 获取的数据
      standardsize: 1 // 全局标准值
    }
  },
  mounted () {
    this.initChart() // 调用初始化
    // this.getData() // 获取服务器数据
    window.addEventListener('resize', this.screenAdapter)
    // 屏幕适配
    this.screenAdapter()
  },
  destroyed () {
    // 移除监听
    window.removeEventListener('resize', this.screenAdapter)
  },
  methods: {
    // 初始化
    initChart () {
      this.chartInstance = echarts.init(this.$refs.usergrowth_ref, 'walden')
      // 对图表初始化配置控制
      const initOption = {
        color: ['#00FFFF', '#ED721D', '#fff'],
        title: { // 标题设置
          text: '增长活跃度',
          textStyle: {
            fontWeight: 'bold'
          },
          left: '3%',
          top: '3%'
        },
        /* legend: {
          orient: 'horizontal'
        }, */
        grid: { // 坐标轴配置
          top: '26%',
          left: '3%',
          right: '6%',
          bottom: '20%',
          containLabel: true // 包含文字

        },

        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        xAxis: {
          type: 'category',
          // boundaryGap: false,
          splitLine: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#3A4755'],
              width: 1,
              type: 'dotted'
            }
          }
        },
        series: [
          {
            name: '新增',

            type: 'bar',
            barMaxWidth: 10,
            itemStyle: {
              color: '#306555' // 柱的颜色
            }
          },
          {
            name: '预约',

            type: 'bar',
            barMaxWidth: 10,
            itemStyle: {
              color: '#1b7f8d' // 柱的颜色
            }
          },
          {
            name: '签到人数',

            type: 'line',
            symbol: 'none', // 去掉折线上的小圆点
            smooth: true,
            lineStyle: {
              color: '#fff'

            }
          },
          {
            name: '新增活跃度',

            symbol: 'none', // 去掉折线上的小圆点
            type: 'line',
            smooth: true,
            lineStyle: {
              color: '#00FFFF'
            }
          }, {
            name: '参与率',

            type: 'line',
            symbol: 'none', // 去掉折线上的小圆点
            smooth: true,
            lineStyle: {
              color: '#ED721D'

            }
          }
        ]
      }
      this.chartInstance.setOption(initOption)
    },
    // 获取服务器数据
    async getData (parameter) {
      steLoading(this.chartInstance)
      const { data } = await GetActivit(parameter)

      this.allData = data.data

      this.$emit('updata', this.allData.sum)
      this.updataChart()
      this.chartInstance.hideLoading()
    },
    // 更新图表
    updataChart () {
      console.log(this.allData)
      const DataOption = {
        xAxis: {
          data: this.allData.x_active_rate
        },
        series: [{
          data: this.allData.y_active_rate
        }, {
          data: this.allData.y_recycle_rate
        }, {
          data: this.allData.y_score_rate
        }, {
          data: this.allData.y_new_user_activity
        },
        {

          data: this.allData.y_part_rate
        }]

      }
      this.chartInstance.setOption(DataOption)
    },
    // 屏幕适配
    screenAdapter () {
      this.standardsize = this.$refs.usergrowth_ref.offsetWidth / 335
      const adapterOption = {
        title: { // 标题设置
          textStyle: {
            fontSize: 16 * this.standardsize
          }
        }
      /*  legend: {
          left: 100 * this.standardsize,
          top: 10 * this.standardsize,
          itemWidth: 30 * this.standardsize,
          itemHeight: 11 * this.standardsize,
          itemGap: 10 * this.standardsize,
          textStyle: {
            fontSize: 11 * this.standardsize
          }

        } */
      }
      this.chartInstance.setOption(adapterOption)
      // 手动调用图表对象resize
      this.chartInstance.resize()
    }
  }
}
</script>

<style scoped>

</style>
