<template>
  <div class="com-father">
    <div ref="realtimeorder_ref" class="com-realtimeorder">
      <h2 class="time-title">实时订单</h2>
      <dv-scroll-board id="ly_board" :config="config" />
      <div class="time-next">
        <p>待接单数：<span class="netx-cole">{{ allData.ordering_num }}</span></p>
        <p>待回收数：<span class="netx-cole">{{ allData.receiving_num }}</span></p>
        <p>待分拣数：<span class="netx-cole">{{ allData.checking_num }}</span></p>
      </div>
    </div>

  </div>
</template>

<script>
import { GetRecyclin } from '@/api/home'
import { steLoading } from '@/utils/loading'

export default {
  data () {
    return {
      chartInstance: null, // 初始化的对象
      allData: {}, // 获取的数据
      standardsize: 1, // 全局标准值
      config: {
        headerHeight: 29, // 表头高度
        headerBGC: '#FFFFFF4C', // 表头颜色
        oddRowBGC: '#FFFFFF00', // 奇数行背景色
        evenRowBGC: '#FFFFFF19', // 偶数行背景色
        rowNum: 4, // 表行数
        header: ['所属社区', '下单时间', '状态', '负责人'],
        data: [

        ]
      }
    }
  },
  mounted () {
    this.initChart() // 调用初始化
    //this.getData() // 获取服务器数据
    window.addEventListener('resize', this.screenAdapter)
    // 屏幕适配
    this.screenAdapter()
  },
  destroyed () {
    // 移除监听
    window.removeEventListener('resize', this.screenAdapter)
  },
  methods: {
    // 初始化
    initChart () {

    },
    // 获取服务器数据
    async getData (parameter) {
      // 获取排名数据

      const { data: { data: res } } = await GetRecyclin(parameter)
      this.allData = res
      var datavs = []
      this.allData.info.forEach(item => {
        var datav = []
        datav.push(item.community_name, item.create_time, item.state, item.su_name === null ? '暂无负责人' : item.su_name)
        datavs.push(datav)
      })
      this.config = {
        headerHeight: 29, // 表头高度
        headerBGC: '#FFFFFF4C', // 表头颜色
        oddRowBGC: '#FFFFFF00', // 奇数行背景色
        evenRowBGC: '#FFFFFF19', // 偶数行背景色
        rowNum: 4, // 表行数
        header: ['所属社区', '下单时间', '状态', '负责人'],
        columnWidth: [100],
        data: datavs
      }

      this.updataChart()
    },
    // 更新图表
    updataChart () {

    },
    // 屏幕适配
    screenAdapter () {
      this.standardsize = this.$refs.realtimeorder_ref.offsetWidth / 335
    }
  }
}
</script>

<style lang="less"  >
@import '~@/assets/css/index.less';
.com-realtimeorder{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: space-between;
  .time-title{
    flex: 1;
    color: #00FFFF;
    margin: 7px 0 10px 16px;
    font-size: 19px;
    font-weight: 600;
  }
  #ly_board{
    flex: 4;
    .header{
      flex-wrap: nowrap;
      height: auto;
      .header-item{
        color: #00FFFF;
      }
    }
    .rows{
      .row-item{
        .ceil{
          font-size: 13px;

        }
      }
    }
  }

  .time-next{
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    color: white;

    p{
      margin: 0;
      font-size: 14px;
      display: flex;
      align-items: center;
      span{
        color: @major-color;
        font-size: 22px;
      }
    }
  }
}

</style>
