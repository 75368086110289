import Vue from 'vue'
// import { borderBox1, loading, fullScreenContainer } from '@jiaminghi/data-view'
//
// Vue.use(borderBox1,
//   fullScreenContainer,
//   loading
// )
// 将自动注册所有组件为全局组件
import { borderBox9, fullScreenContainer, loading, decoration3, scrollBoard } from '@jiaminghi/data-view'

Vue.use(fullScreenContainer)
Vue.use(loading)
Vue.use(decoration3)
Vue.use(scrollBoard)
Vue.use(borderBox9)
