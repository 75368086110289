import axios from './index'

// 登录
export const GetTan = params => {
  return axios.request({
    url: '/recycle_class_rate_detail',
    method: 'get',
    params
  })
}
export const GetTongHuanl = params => {
  return axios.request({
    url: '/recycle_class_ratio',
    method: 'get',
    params
  })
}
export const GetOncall = params => {
  return axios.request({
    url: '/oncall',
    method: 'get',
    params
  })
}

