<template>
<div class="com-father">
  <div>
    <svg width="0" height="0">
      <defs>
        <filter id="goo">
          <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur"></feGaussianBlur>
          <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo"></feColorMatrix>
          <feComposite in="SourceGraphic" in2="goo" operator="atop"></feComposite>
        </filter>
      </defs>
    </svg>
    <div class="aside-nav bounceInUp animated" id="aside-nav" @mousedown="move">
      <label for="" class="aside-menu" title="按住拖动">菜单</label>
      <a href="/" title="返回首页" class="menu-item menu-first">主页</a>

      <el-popover v-if="funnname ==='主'"
        placement="right"
        width="400"
        trigger="hover">
<!--        <el-input placeholder="请输入内容" v-model="state1" @select="handleSelect" :fetch-suggestions="querySearch" class="input-with-select"> <el-button slot="append" icon="el-icon-search"></el-button></el-input>-->
        <el-autocomplete
          class="inline-input"
          v-model="state1"
          :fetch-suggestions="querySearch"
          placeholder="请输入内容"
          @select="handleSelect"
        ></el-autocomplete>
      <a href="javascript:void(0)" title="搜索" class="menu-item menu-second"  slot="reference">搜索</a>
      </el-popover>
      <a href="javascript:history.back(-1)" v-else title="搜索" class="menu-item menu-second"  slot="reference">返回</a>
      <a href="#/communitydetails" title="小区列表" class="menu-item menu-line menu-third">小区<br>列表</a>
      <a href="#/rankingdetails" title="小区排行" class="menu-item menu-line menu-fourth">小区<br>排行</a> <!-- *跳到商家微信推广* -->
    </div>
  </div>

</div>
</template>

<script>
import { GetMap } from '@/api/home'

export default {
  props:['funnname'],
  data () {
    return {
      positionX: 0,
      positionY: 0,
      restaurants: [],
      state1: '',
      stetimeName: ''
    }
  },
  mounted () {
    this.getlist()
  },

  methods: {
    async getlist () {
      const { data: { data } } = await GetMap()
      data.areas.forEach(item => {
        var neobj = {}
        neobj.value = item.name
        neobj.id = item.id
        neobj.centre = item.centre
        this.restaurants.push(neobj)
      })
    },
    querySearch (queryString, cb) {
      var restaurants = this.restaurants
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter (queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    },
    handleSelect (item) {
      this.$emit('detailssearch', item)
    },

    move (e) {
      /*
* 颜色
* 痛点
* 社区名称
* */

      var ua = navigator.userAgent; /Safari|iPhone/i.test(ua) && /chrome/i.test(ua) == 0 && $('#aside-nav').addClass('no-filter')
      var drags = { down: !1, x: 0, y: 0, winWid: 0, winHei: 0, clientX: 0, clientY: 0 }
      var asideNav = $('#aside-nav')[0]
      var getCss = function (a, e) { return a.currentStyle ? a.currentStyle[e] : document.defaultView.getComputedStyle(a, !1)[e] }
      $('#aside-nav').on('mousedown', function (a) {
        drags.down = !0,
        drags.clientX = a.clientX,
        drags.clientY = a.clientY,
        drags.x = getCss(this, 'right'),
        drags.y = getCss(this, 'top'),
        drags.winHei = $(window).height(),
        drags.winWid = $(window).width(),
        $(document).on('mousemove', function (a) {
          if (drags.winWid > 640 && (a.clientX < 120 || a.clientX > drags.winWid - 50))// 50px
          { return !1 } /*, console.log(!1) */
          if (a.clientY < 180 || a.clientY > drags.winHei - 120)// 瀵艰埅楂樺害
          { return !1 }
          var e = a.clientX - drags.clientX
          var t = a.clientY - drags.clientY
          asideNav.style.top = parseInt(drags.y) + t + 'px'
          asideNav.style.right = parseInt(drags.x) - e + 'px'
        })
      }).on('mouseup', function () { drags.down = !1, $(document).off('mousemove') })
    }
  }
}
</script>

<style scoped>
.el-autocomplete{
  width: 100%;
}
</style>
