import axios from './index'
// 获取地图数据表
export const GetMap = params => {
  return axios.request({
    url: '/communities',
    method: 'get',
    params
  })
}
// 获取地区范围
export const GetWei = params => {
  return axios.request({
    url: '/position',
    method: 'get',
    params
  })
}
// 获取实时订单
export const GetRecyclin = params => {
  return axios.request({
    url: '/oncall',
    method: 'get',
    params
  })
}
// 获取社区参与率排名
export const GetCommunity = params => {
  return axios.request({
    url: '/community_top',
    method: 'get',
    params
  })
}
// 获取分类占比
export const GetRecycleClass = params => {
  return axios.request({
    url: '/recycle_class_rate',
    method: 'get',
    params
  })
}

// 环保金
export const Getmoney = params => {
  return axios.request({
    url: '/money',
    method: 'get',
    params
  })
}
// 右边
// 开通社区
export const GetStatistical = params => {
  return axios.request({
    url: '/statistical_data',
    method: 'get',
    params
  })
}
// 获取参与率三滤
export const GetUserData = params => {
  return axios.request({
    url: '/user_rate_data',
    method: 'get',
    params
  })
}
// 回收量统计
export const GetRecycle = params => {
  return axios.request({
    url: '/recycle_data',
    method: 'get',
    params

  })
}
// 获取用户活跃度
export const GetActivit = params => {
  return axios.request({
    url: '/activity_statistics',
    method: 'get',
    params
  })
}

// 获取社区排名
export const GetCommun = params => {
  return axios.request({
    url: '/community_top',
    method: 'get',
    params
  })
}
// 小区个人排名
export const GetPersonal = params => {
  return axios.request({
    url: '/recycling_weight_top', // 跨域了
    method: 'get',
    params
  })
}
// 获取服务人员的乱七八糟信息
export const GetTotal = params => {
  return axios.request({
    url: '/total_data',
    method: 'get',
    params
  })
}

// 获取小区详情
export const Getcominit = params => {
  return axios.request({
    url: '/community_detail',
    method: 'get',
    params
  })
}

// 获取桶点数
export const Getbuckets = params => {
  return axios.request({
    url: '/buckets',
    method: 'get',
    params
  })
}
// 获取用户数
export const Getusers = params => {
  return axios.request({
    url: '/statistical_data',
    method: 'get',
    params
  })
}
// 获取账户下街道信息

export const Getusergeo = params => {
  return axios.request({
    url: '/user_geo',
    method: 'get',
    params
  })
}

// 转运站
export const GetValve = params => {
  return axios.request({
    url: '/valve',
    method: 'get',
    params
  })
}
