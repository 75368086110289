<template>
  <div class="com-father">
    <div ref="tan_ref" class="com-tan">
      <!--
      分类类别同比环比
      -->

    </div>
    <div class="classbut">
      <div class="but-item" @click="butlajifun(item.trem)" :style="{background:item.type?'#8A95A6':'#515F73'}" v-for="item in butdata" :key="item.id">
        <span class="item-se" :style="{background:item.color}"></span>
        <span class="item-text">{{ item.text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { GetActivit, Getmoney, GetRecycle } from '@/api/home'
import { steLoading } from '@/utils/loading'
import { godou } from '@/utils/local'
import { GetTan, GetTongHuanl } from '@/api/tan'
export default {
  data () {
    return {
      chartInstance: null, // 初始化的对象
      allData: [], // 获取的数据
      standardsize: 1, // 全局标准值
      datanaum: [],
      datasd: [

      ],
      yuanshuzu: null,
      trems: 'avalible', // 默认的垃圾选项
      butdata: [ // 各种垃圾的按钮
        {
          id: 1,
          type: true, // 选中状态
          text: '可回收物',
          color: '#325DD8',
          trem: 'avalible'
        },
        {
          id: 2,
          type: false, // 选中状态
          text: '有害垃圾',
          color: '#D13430',
          trem: 'harm'
        },
        {
          id: 3,
          type: false, // 选中状态
          text: '厨余垃圾',
          color: '#39B54A',
          trem: 'kitchen'
        },
        {
          id: 4,
          type: false, // 选中状态
          text: '其他垃圾',
          color: '#6D6D6D',
          trem: 'avalible'
        },
        {
          id: 5,
          type: false, // 选中状态
          text: '废旧家电',
          color: '#719DFF',
          trem: 'appliance'
        },
        {
          id: 6,
          type: false, // 选中状态
          text: '大件垃圾',
          color: '#D69834',
          trem: 'huge'
        }
      ]
    }
  },
  mounted () {
    this.initChart() // 调用初始化
    // this.getData() // 获取服务器数据
    window.addEventListener('resize', this.screenAdapter)
    // 屏幕适配
    this.screenAdapter()
  },
  destroyed () {
    // 移除监听
    window.removeEventListener('resize', this.screenAdapter)
  },
  methods: {
    // 初始化
    initChart () {
      this.chartInstance = echarts.init(this.$refs.tan_ref, 'walden')
      this.chartInstance.clear()
      // 对图表初始化配置控制
      const initOption = {
        grid: { // 坐标轴配置
          top: '20%',
          left: '3%',
          right: '6%',
          bottom: '20%',
          height: 280,

          containLabel: true // 包含文字

        },
        color: ['#ED721D', '#29ABE2', '#00ffff'],
        legend: {
          bottom: 0,
          itemGap: 40
        },
        tooltip: {
          trigger: 'axis',
          formatter: (params) => {
            let str = params[0].axisValue + '<br />'

            params.forEach((item) => {
              str +=
                `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:${item.color} "></span>${item.seriesName}: ${item.value} ${item.seriesName === '重量' ? 't' : '%'}<br />`
            })
            return str
          },
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#b2b2b2'
            }
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              width: 4
            }
          },
          splitLine: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            lineStyle: {
              width: 4
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#b2b2b2'],
              width: 1,
              type: 'dashed'
            }
          }
        }
      }

      this.chartInstance.setOption(initOption)
    },
    // 获取服务器数据
    async getData (parameter) {

      steLoading(this.chartInstance)
      this.allData = []
      // 获取分类数据同比环比
      const { data: { data: res } } = await GetTongHuanl(parameter)

      this.yuanshuzu = res
      this.allData = this.jihuantongbu(res, this.terms)
      this.chartInstance.hideLoading()
      this.updataChart()
    },
    // 计算单个同比环比
    /*
    * 数组
    * 条件
    * */
    butlajifun (e) {
      // 点击垃圾按钮后切换数据
      this.butdata.forEach(item => {
        item.trem === e ? item.type = true : item.type = false
      })
      this.terms = e
      this.allData = this.jihuantongbu(this.yuanshuzu, e)
      this.updataChart()
    },
    jihuantongbu (arr, term) {
      term = term === undefined ? 'avalible' : term
      var alldatazi = []
      // x轴
      alldatazi[0] = arr.x
      // Y轴 同比
      var y1zhi = [{
        color: '#ED721D',
        type: 'line',
        data: [],
        name: '同比'
      }]
      arr.y1.forEach(item => {
        var datazhi = (parseInt(item[term].data)).toFixed(2)
        y1zhi[0].data.push(datazhi)
        // y1zhi[0].name = item[term].name
      })
      alldatazi[1] = y1zhi
      // Y轴 环比
      var y2zhi = {
        color: '#29ABE2',
        type: 'line',
        data: [],
        name: '环比'
      }
      arr.y2.forEach(item => {
        // 转化为吨 第一年没有值的情况下只能这么大
        var datazhi = (parseFloat(item[term].data) * 100).toFixed(2)

        y2zhi.data.push(datazhi)
        // y2zhi[0].name = item[term].name
      })
      alldatazi[1].push(y2zhi)
      // Y轴 值
      var y3zhi = {
        color: '#00FFFF',
        type: 'bar',
        data: [],
        name: '重量'
      }
      arr.y3.forEach(item => {
        // 转化为吨
        var datazhi = (parseInt(item[term].data) / 1000).toFixed(2)

        y3zhi.data.push(datazhi)
      })
      alldatazi[1].push(y3zhi)
      return alldatazi
    },
    // 更新图表
    updataChart () {
      var datas = []
      this.allData[1].forEach(item => {
        if (item.type === 'line') {
          datas.push(
            {
              data: item.data,
              name: item.name,
              type: 'line',
              huan: item.huan,
              tong: item.tong,
              symbol: 'none', // 去掉折线上的小圆点
              smooth: true,
              lineStyle: {
                color: item.color,
                width: 4
              }
            })
        }
        if (item.type === 'bar') {
          datas.push({
            name: item.name,
            data: item.data,
            type: 'bar',
            barMaxWidth: 10,
            itemStyle: {
              color: item.color // 柱的颜色
            }
          })
        }
      })
      this.datanaum = datas
      // console.log(datas)
      const DataOption = {
        xAxis: {
          data: this.allData[0]
        },
        series: datas

      }

      this.chartInstance.setOption(DataOption)
    },
    // 屏幕适配
    screenAdapter () {
      this.standardsize = this.$refs.tan_ref.offsetWidth / 335
      const adapterOption = {
      }
      this.chartInstance.setOption(adapterOption)
      // 手动调用图表对象resize
      this.chartInstance.resize()
    }
  }
}
</script>

<style lang="less" scoped>
.com-tan{
  width: 100%;
  height: 100%}
.classbut{
  width: 75%;
  height: 34px;
  margin-right: 0;
  position: absolute;
  right: 2%;
  top: 6%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: space-around;
  .but-item{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 5px 0;
    width: 98px;
    height: 18px;
    border-radius: 6px;
    background: #515F73;
    cursor:pointer;
    .item-se{
      width: 19px;
      height: 13px;
      background: #325DD8;
      border-radius: 3px;
      display: inline-block;

    }
    .item-text{
      font-family: SourceHanSansCN-Bold-GBpc-EUC-H;
      line-height: 18px;
      color: #FFFFFF;
    }
  }

}
</style>
