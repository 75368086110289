<template>
  <div id="app">
 <router-view></router-view>

  </div>
</template>

<script>
import { refreshScale } from '@/utils/foisfix'

export default {
  name: 'app',
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isRouterAlive: true
    }
  },
  mounted () {
    refreshScale()
    window.onresize = function () {
      refreshScale()
    }
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  }
}
</script>

<style>

</style>
